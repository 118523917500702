@import '~antd/dist/antd.css';
@import '~react-big-calendar/lib/css/react-big-calendar.css';
@import '~react-big-calendar/lib/addons/dragAndDrop/styles.css';
@import '~antd/es/modal/style/index.css';
@import '~antd/es/slider/style/index.css';
@import '~codemirror/lib/codemirror.css';
@import '~codemirror/theme/material.css';

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

@import './dark.css';

:root {
  --base-radius: 4px;
  --main-wrapper-heigth: 0px;
  --widget-purple: #7333fb;
  --widget-orange: #ff8f63;
  --widget-yellow: #ffca39;
  --widget-green: #42bb56;
  --widget-main: #fff;
  --widget-sidebar: #edf2fb;
}

#root {
  height: 100%;
}

.avatar-uploader {
  width: auto;
  margin: 0 auto;
  display: block;
}

.ant-table-small {
  max-height: calc(var(--main-wrapper-heigth));
  overflow: hidden;
}

.ant-table-body {
  max-height: calc(var(--main-wrapper-heigth) - 120px) !important;
}

.ant-drawer-body {
  padding: 0;
}

.reservation-drawer > .ant-drawer-content {
  background: #001529;
}

.reservation-drawer {
  background: #ffffff;
}

.ant-picker-now-btn {
  display: none;
}

@media only screen and (max-width: 800px) {
  .ant-layout-content {
    padding: 24px 0 !important;
  }
  .phone-button-margin {
    margin: 0 24px;
    width: 100%;
  }
  .phone-button-margin-full {
    margin-left: 24px;
    width: calc(100% - 48px) !important;
  }
  .ant-tabs-nav-wrap {
    margin: 0 24px;
  }
  .mobile-left-margin {
    margin-left: 24px;
  }
  .mobile-left-padding {
    padding-left: 24px;
  }
  .ant-picker-now-btn {
    display: none;
  }
}

.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-disabled.ant-select-multiple .ant-select-selector,
.ant-picker.ant-picker-disabled,
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.65);
  background: rgba(245, 245, 245, 0.33);
}

.site-page-header {
  border: 1px solid rgba(0, 0, 0, 0.22);
}

.no-arrows-input > .ant-input-number-handler-wrap {
  display: none;
}

.main-drawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-body {
  background: #001529;
}

.spin-ant-d {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}

.adding-margin {
  margin-top: 20px;
}

.spin-container {
  text-align: center;
  width: 100%;
}



.flex { display: flex; }
.flex-column { flex-direction: column; }
.flex-grow-1 { flex-grow: 1; }
.flex-shrink-0 { flex-shrink: 0; }
.flex-wrap { flex-wrap: wrap; }

.align-items-center { align-items: center; }
.justify-content-center { justify-content: center; }
.justify-content-right { justify-content: right; }

.w-25 { width: 25%; }
.w-full { width: 100%; }

.break-work { word-break: break-word; }
.text-wrap { white-space: normal; }
.text-12 { font-size: 12px; }
.text-14 { font-size: 14px; }
.text-16 { font-size: 16px; }
.text-bold { font-weight: bold; }
.text-center { text-align: center; }

.mb-1 { margin-bottom: 4px; }
.mb-2 { margin-bottom: 8px; }
.mb-3 { margin-bottom: 16px; }
.mb-4 { margin-bottom: 24px; }
.ml-1 { margin-left: 4px; }
.ml-2 { margin-left: 8px; }
.ml-3 { margin-left: 16px; }
.ml-4 { margin-left: 24px; }
.mr-1 { margin-right: 4px; }
.mr-2 { margin-right: 8px; }
.mr-3 { margin-right: 16px; }
.mr-4 { margin-right: 24px; }
.mt-1 { margin-top: 4px; }
.mt-2 { margin-top: 8px; }
.mt-3 { margin-top: 16px; }
.mt-4 { margin-top: 24px; }
.m--1 { margin: -4px; }

.p-1 { padding: 4px; }



:root {
  --color-green: rgb(124, 179, 5);
  --color-red: rgb(212, 56, 13);
}

.color-gray { color: #999; }
.color-green { color: var(--color-green); }
.color-red { color: var(--color-red); }


.skeedee-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

.skeedee-list .list-item {
  background: #fff;
  font-size: 11px;
  padding: 15px 16px;
}

/* .skeedee-dark .skeedee-list .list-item {
  background: #585a70;
} */

.skeedee-list .list-item:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.skeedee-list .list-item:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.skeedee-list .list-item:not(:last-child) {
  box-shadow: 0px 1px 0px 0px #0f0aa733;
  margin-bottom: 1px;
}

/* .skeedee-dark .skeedee-list .list-item:not(:last-child) {
  box-shadow: 0px 1px 0px 0px #0f0aa733;
} */

.skeedee-list .list-item:active,
.skeedee-list .list-item:hover {
  background: #8b9ef933;
  box-shadow: 0px 0px 10px 0px #d2daff4d inset;
}

/* .skeedee-dark .skeedee-list .list-item:active,
.skeedee-dark .skeedee-list .list-item:hover {
  background: #484a5c;
  box-shadow: none;
} */



.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content.ant-picker-calendar-date-content {
  height: 0;
}



/* .skeedee-dark .ant-carousel {
  color: #fff;
} */

.ant-carousel .slick-slide.slick-slide {
  height: 1px;
}

.ant-carousel .slick-slide.slick-slide.slick-active,
.ant-carousel .slick-slide.slick-slide.slick-current {
  height: auto;
}

.ant-carousel .slick-dots {
  display: none !important;
}



/* .skeedee-dark .ant-form-item-label > label {
  color: #fff;
}

.skeedee-dark .ant-input,
.skeedee-dark .ant-input-number,
.skeedee-dark .ant-input-affix-wrapper {
  background: #585a70;
  border-color: #0f0aa733;
  color: #fff;
}

.skeedee-dark .ant-input-number {
  background: #484a5c;
}

.skeedee-dark .ant-input:focus,
.skeedee-dark .ant-input-focused,
.skeedee-dark .ant-input:hover,
.skeedee-dark .ant-input-number:focus,
.skeedee-dark .ant-input-number-focused,
.skeedee-dark .ant-input-number:hover,
.skeedee-dark .ant-input-affix-wrapper:focus,
.skeedee-dark .ant-input-affix-wrapper-focused,
.skeedee-dark .ant-input-affix-wrapper:hover {
  background: #484a5c;
  border-color: #efc905;
}

.skeedee-dark .ant-input-affix-wrapper .ant-input,
.skeedee-dark .ant-input-affix-wrapper .ant-input:focus,
.skeedee-dark .ant-input-affix-wrapper .ant-input:hover {
  background: transparent;
}

.skeedee-dark .ant-input-suffix svg path {
  fill: rgba(255, 255, 255, .65);
}

.skeedee-dark .ant-input-suffix svg:hover path {
  fill: #fff;
}

.skeedee-dark .ant-checkbox-wrapper {
  color: #fff;
}

.skeedee-dark .ant-input-group-addon {
  border: none;
}

.skeedee-dark .ant-select,
.skeedee-dark .ant-input-group-addon .ant-select-open .ant-select-selector,
.skeedee-dark .ant-input-group-addon .ant-select-focused .ant-select-selector {
  color: #fff;
}

.skeedee-dark .ant-select-arrow .anticon > svg path {
  fill: rgba(255, 255, 255, .65);
}

.skeedee-dark .ant-select:hover .ant-select-arrow .anticon > svg path,
.skeedee-dark .ant-select-open .ant-select-arrow .anticon > svg path {
  fill: #fff;
}

.skeedee-dark .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.skeedee-dark .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background: #585a70;
  border-color: #0f0aa733;
}

.skeedee-dark .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.skeedee-dark .ant-input-group-addon .ant-select-focused.ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background: #484a5c;
  border-color: #efc905;
  box-shadow: 0 0 0 2px rgba(239, 201, 5, .2);
}

.skeedee-dark .ant-input-group-addon {
  background: #484a5c;
}

.skeedee-dark .ant-select-dropdown {
  background: #585a70;
}

.skeedee-dark .ant-select-item-option {
  color: #fff;
}

.skeedee-dark .ant-select-item-option:hover,
.skeedee-dark .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: #484a5c;
}

.skeedee-dark .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: rgba(239, 201, 5, .25);
}

.skeedee-dark .ant-divider {
  border-color: rgba(47, 48, 61, .25);
} */



.phone-input-group.ant-input-group.ant-input-group-compact {
  display: flex;
}

.phone-input-group.ant-input-group.ant-input-group-compact .ant-select {
  flex-shrink: 0;
  width: 120px;
}

.phone-input-group.ant-input-group.ant-input-group-compact > .ant-input {
  flex-grow: 1;
}



/* .skeedee-dark .ant-tabs {
  color: #fff;
}

.skeedee-dark .ant-tabs-top > .ant-tabs-nav::before {
  border-color: rgba(47, 48, 61, .65);
}

.skeedee-dark .ant-tag {
  background: #585a70;
  border-color: #484a5c;
  color: #fff;
}

.skeedee-dark .ant-tag:hover {
  background: #484a5c;
}

.skeedee-dark .ant-tabs-dropdown-menu {
  background: #585a70;
}

.skeedee-dark .ant-tabs-dropdown-menu-item {
  color: #fff;
}

.skeedee-dark .ant-tabs-dropdown-menu-item:hover {
  background: #484a5c;
}



.skeedee-dark .ant-btn-dashed {
  background: #585a70;
  border-color: #484a5c;
  color: #fff;
}

.skeedee-dark .ant-btn-dashed:hover {
  background: #484a5c;
  color: #40a9ff;
}

.skeedee-dark .ant-btn-dashed.ant-btn-dangerous {
  border-color: #ff7875;
}

.skeedee-dark .ant-btn-dashed.ant-btn-dangerous:hover {
  color: #ff7875;
}



.skeedee-dark .ant-drawer-header {
  background: #3f404a;
  border-color: #484a5c;
}

.skeedee-dark .ant-drawer-title {
  color: #fff;
}

.skeedee-dark .ant-drawer-header button {
  color: rgba(255, 255, 255, .65);
}

.skeedee-dark .ant-drawer-header button:hover {
  color: #fff;
}

.skeedee-dark .ant-drawer-body {
  background: #3f404a;
} */
