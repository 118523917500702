.reservation-masters .list-item {
  cursor: pointer;
}

.reservation-masters .list-item--disabled {
  cursor: default;
  opacity: .5;
}

.reservation-masters .avatar-slot {
  background: #c4c4c4;
  border-radius: 10px;
  flex-shrink: 0;
  height: 60px;
  margin-right: 16px;
  overflow: hidden;
  position: relative;
  width: 60px;
}

.reservation-masters .title {
  font-size: 16px;
}

.reservation-masters .ant-rate-star:not(:last-child) {
  margin-right: 4px;
}

.reservation-masters .ant-rate-star-second svg {
  font-size: 12px;
}

.reservation-masters .reviews-button {
  margin: -8px;
}

.reservation-masters .reservation-time-label {
  font-size: 14px;
}

.reservation-masters .reservation-time {
  margin-top: -12px;
}
