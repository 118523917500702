.widget-toolbar {
  background: #fff;
  bottom: 0;
  height: 57px;
  left: 0;
  position: fixed;
  right: 0;
}

/* .skeedee-dark .widget-toolbar {
  background: #585a70;
} */

.widget-toolbar ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.widget-toolbar li {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 57px;
  width: 25%;
}

.widget-toolbar li.active {
  background: #fff000;
  background: linear-gradient(180deg, #fff000 0%, #ffd600 100%);
}

/* .skeedee-dark .widget-toolbar li.active {
  background: #ceef01;
  background: linear-gradient(180deg, #ceef01 0%, #c3e200 100%);
  color: #000;
} */

.widget-toolbar svg {
  margin-top: 7px;
}

/* .skeedee-dark .widget-toolbar svg path {
  fill: #fff;
}

.skeedee-dark .widget-toolbar li.active svg path {
  fill: #000;
} */

.widget-toolbar span {
  font-size: 11px;
  margin-top: 2px;
  text-align: center;
}
