.widget-header {
  align-items: center;
  background: #4926ad;
  background: linear-gradient(180deg, #4926ad 0%, #403b97 100%);
  display: flex;
  flex-direction: column;
  height: 46px;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}

/* .skeedee-dark .widget-header {
  background: #2f303d;
  background: linear-gradient(180deg, #2f303d 0%, #23242d 100%);
} */

.widget-header h3 {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  padding: 0 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.widget-header .subtitle {
  color: #fff;
  color: rgba(255, 255, 255, .85);
  margin-top: -8px;
  max-width: 100%;
  overflow: hidden;
  padding: 0 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
