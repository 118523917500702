.bookings-reservations .list-item {
  position: relative;
}

.bookings-reservations .title {
  font-size: 16px;
}

.bookings-reservations p {
  margin: 0;
}

.bookings-reservations .actions {
  margin-top: 8px;
}

.bookings-reservations .status {
  width: 10px;
  height: 10px;
  position: absolute;
  right: 14px;
  border-radius: 50%;
}

.bookings-reservations .status--created {
  background: rgb(24, 144, 255);
}

.bookings-reservations .status--done {
  background: var(--color-green);
}

.bookings-reservations .status--inProgress {
  background: rgb(8, 151, 156);
}

.bookings-reservations .status--canceled {
  background: var(--color-red);
}

.bookings-reservations .status--needUpdate {
  background: rgb(196, 29, 127);
}
