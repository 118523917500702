@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
.skeedee-loader .anticon-loading {
  font-size: 64px;
  margin: 10% auto 50px auto;
  display: block;
}

.skeedee-loader h2.ant-typography {
  text-align: center;
  font-weight: 200 !important;
}

/* .skeedee-dark .skeedee-loader h2.ant-typography {
  color: #fff;
} */

.countries-form-item .ant-select-selection-item .country-name {
  margin-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.reservation-header {
  display: flex;
  margin-bottom: 24px;
}

.reservation-header .icon-button {
  border: none;
  border-radius: 10px;
  box-shadow: 0px 5px 25px 0px #0000000d;
  height: 48px;
  width: 54px;
}

/* .skeedee-dark .reservation-header .icon-button {
  background: #585a70;
  box-shadow: 0px 5px 25px 0px #00000033;
} */

.reservation-header .label-slot {
  align-items: center;
  display: flex;
  flex-grow: 1;
  font-size: 14px;
  justify-content: center;
}

.reservation-header .label-slot b {
  color: #0f0aa7;
  text-transform: uppercase;
}

/* .skeedee-dark .reservation-header .label-slot b {
  color: #efc905;
} */

.reservation-locations .list-item {
  cursor: pointer;
  display: flex;
}

.reservation-locations .avatar-slot {
  background: #c4c4c4;
  border-radius: 10px;
  flex-shrink: 0;
  height: 60px;
  margin-right: 16px;
  overflow: hidden;
  position: relative;
  width: 60px;
}

.reservation-locations .title {
  font-size: 16px;
}

.reservation-masters .list-item {
  cursor: pointer;
}

.reservation-masters .list-item--disabled {
  cursor: default;
  opacity: .5;
}

.reservation-masters .avatar-slot {
  background: #c4c4c4;
  border-radius: 10px;
  flex-shrink: 0;
  height: 60px;
  margin-right: 16px;
  overflow: hidden;
  position: relative;
  width: 60px;
}

.reservation-masters .title {
  font-size: 16px;
}

.reservation-masters .ant-rate-star:not(:last-child) {
  margin-right: 4px;
}

.reservation-masters .ant-rate-star-second svg {
  font-size: 12px;
}

.reservation-masters .reviews-button {
  margin: -8px;
}

.reservation-masters .reservation-time-label {
  font-size: 14px;
}

.reservation-masters .reservation-time {
  margin-top: -12px;
}

.reservation-cart.skeedee-list .list-item {
  font-size: 16px;
}

.reservation-cart.skeedee-list .list-item--clickable {
  cursor: pointer;
}

.reservation-cart.skeedee-list .list-item--disabled {
  color: #999;
  cursor: default;
}

.reservation-cart .list-item .icon-slot {
  background: #f6f7ff;
  border-radius: 100%;
  height: 48px;
  margin-right: 12px;
  width: 48px;
}

.reservation-cart .list-item .icon-button {
  border-radius: 100%;
  height: 32px;
  width: 32px;
}

.reservation-cart .list-item .icon-button svg {
  color: #999;
}

.reservation-cart .list-item .services-list {
  border-top: 1px solid #f6f7ff;
  list-style: none;
  margin: 15px 0 0;
  padding: 15px 0 0;
}

.reservation-cart .list-item .services-list li:not(:first-child) {
  margin-top: 8px;
}

.reservation-cart--continue-button.ant-btn {
  bottom: 81px;
  left: 24px;
  position: fixed;
  right: 24px;
}

.reservation-services .list-item {
  cursor: pointer;
}

.reservation-services.skeedee-list .list-item--disabled {
  cursor: default;
  opacity: .5;
}

.reservation-services.skeedee-list .list-item--selected {
  border-right: 5px solid var(--color-green);
  padding-right: 11px;
}

.reservation-services .title {
  font-size: 16px;
}

.reservation-services .price {
  font-size: 22px;
}

.reservation-services .selectivity-icon {
  font-size: 20px;
}

.reservation-services .service-duration {
  color: #e5165c;
  font-size: 14px;
}

.reservation-services .description {
  color: #777;
  font-size: 13px;
  line-height: 18px;
}

.reservation-services .more-button {
  font-size: 12px;
  margin-top: -8px;
}

.reservation-calendar--months .month-item {
  justify-content: center;
  width: 33%;
}

.reservation-calendar--months .month-item:first-child,
.reservation-calendar--months .month-item:last-child {
  color: #999;
}

.reservation-calendar--months .month-item:first-child {
  justify-content: left;
}

.reservation-calendar--months .month-item:last-child {
  justify-content: right;
}

.reservation-calendar--months .month-item span {
  cursor: pointer;
  text-transform: uppercase;
}

.reservation-calendar--days {
  overflow-x: scroll;
}

.reservation-calendar--days .day-item {
  float: left;
}

.reservation-calendar--days .day-item:last-child {
  margin-right: 0;
}

.reservation-calendar--days .day-item .day-name {
  color: #777;
  text-transform: uppercase;
  width: 32px;
}

.reservation-calendar--days .day-item--disabled .day-name {
  color: #bbb;
}

.reservation-calendar--days .day-item .day-button {
  border-radius: 100%;
  cursor: pointer;
  height: 32px;
  width: 32px;
}

.reservation-calendar--days .day-item--disabled .day-button {
  color: #999;
  cursor: default;
}

.reservation-calendar--days .day-item--current .day-button {
  background: #0f0aa733;
  cursor: default;
}

.about-us {
  font-size: 14px;
  word-break: break-all;
}

/* .skeedee-dark .about-us .ant-typography {
  color: #fff;
} */

.about-us .avatar img {
  border-radius: 100%;
  height: 64px;
  width: 64px;
}

.reservation-info,
.reservation-info ul {
  list-style: none;
  padding: 0;
}

.location-info {
  display: flex;
}

.location-info img {
  flex-shrink: 0;
  margin-right: 4px;
}

.bookings-reservations .list-item {
  position: relative;
}

.bookings-reservations .title {
  font-size: 16px;
}

.bookings-reservations p {
  margin: 0;
}

.bookings-reservations .actions {
  margin-top: 8px;
}

.bookings-reservations .status {
  width: 10px;
  height: 10px;
  position: absolute;
  right: 14px;
  border-radius: 50%;
}

.bookings-reservations .status--created {
  background: rgb(24, 144, 255);
}

.bookings-reservations .status--done {
  background: var(--color-green);
}

.bookings-reservations .status--inProgress {
  background: rgb(8, 151, 156);
}

.bookings-reservations .status--canceled {
  background: var(--color-red);
}

.bookings-reservations .status--needUpdate {
  background: rgb(196, 29, 127);
}

.widget-header {
  align-items: center;
  background: #4926ad;
  background: linear-gradient(180deg, #4926ad 0%, #403b97 100%);
  display: flex;
  flex-direction: column;
  height: 46px;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}

/* .skeedee-dark .widget-header {
  background: #2f303d;
  background: linear-gradient(180deg, #2f303d 0%, #23242d 100%);
} */

.widget-header h3 {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  padding: 0 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.widget-header .subtitle {
  color: #fff;
  color: rgba(255, 255, 255, .85);
  margin-top: -8px;
  max-width: 100%;
  overflow: hidden;
  padding: 0 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.provider-link {
  font-size: 11px;
  margin-top: 22px;
  padding: 0 36px;
  text-align: center;
}

.provider-link a {
  color: #0f0aa7;
  font-weight: bold;
  text-decoration: underline;
  text-transform: uppercase;
}

/* .skeedee-dark .provider-link a {
  color: #efc905;
} */

.widget-toolbar {
  background: #fff;
  bottom: 0;
  height: 57px;
  left: 0;
  position: fixed;
  right: 0;
}

/* .skeedee-dark .widget-toolbar {
  background: #585a70;
} */

.widget-toolbar ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.widget-toolbar li {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 57px;
  width: 25%;
}

.widget-toolbar li.active {
  background: #fff000;
  background: linear-gradient(180deg, #fff000 0%, #ffd600 100%);
}

/* .skeedee-dark .widget-toolbar li.active {
  background: #ceef01;
  background: linear-gradient(180deg, #ceef01 0%, #c3e200 100%);
  color: #000;
} */

.widget-toolbar svg {
  margin-top: 7px;
}

/* .skeedee-dark .widget-toolbar svg path {
  fill: #fff;
}

.skeedee-dark .widget-toolbar li.active svg path {
  fill: #000;
} */

.widget-toolbar span {
  font-size: 11px;
  margin-top: 2px;
  text-align: center;
}

.widget-viewport {
  background: #f6f7ff;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', 'Quicksand', Helvetica, sans-serif;
  font-size: 14px;
  margin: 0;
  min-height: 100%;
  padding: 70px 24px 81px;
  transition-duration: 0.1s;
}

/* .skeedee-dark .widget-viewport {
  background: #3f404a;
  color: #fff;
} */

.skeedee-dark .widget-viewport{
  background: #262a48;
}

.skeedee-dark .reservation-header > label-slot,
.skeedee-dark .reservation-services .title,
.skeedee-dark .reservation-services .price,
.skeedee-dark .provider-link,
.skeedee-dark .skeedee-list .list-item,
.skeedee-dark .reservation-masters b.title,
.skeedee-dark .reservation-slide,
.skeedee-dark form .ant-form label,
.skeedee-dark .ant-tabs-tab .ant-tabs-tab-btn,
.skeedee-dark .ant-form-item-label>label,
.skeedee-dark .ant-checkbox-wrapper,
.skeedee-dark .about-us > article,
.skeedee-dark .reservation-header .label-slot {
  color: #fff;
}

.skeedee-dark ul.skeedee-list > li.list-item {
  background: #535a8c;
}

.skeedee-dark .reservation-header .label-slot b,
.skeedee-dark .provider-link a,
.skeedee-dark .ant-btn-link.reviews-button {
  color: #aca2dc;
}

.skeedee-dark .widget-toolbar > ul {
  background: #e9e3ff;
}

.skeedee-dark .skeedee-list .list-item:active,
.skeedee-dark .skeedee-list .list-item:hover {
  box-shadow: inset 0 0 10px 0 rgb(23 23 24 / 30%);
}

.skeedee-dark .reservation-masters li.list-item .ant-col > div {
  color: #d6d0ea;
}

.skeedee-dark .reservation-header .icon-button {
  background: #e5dffb;
}

.skeedee-dark .reservation-slide .ant-picker-calendar {
  background: #e5dffb;
}

.skeedee-dark .ant-picker-calendar-full .ant-picker-panel {
  background: transparent;
}

.skeedee-dark .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 0;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  border-top: 2px solid #e5dffb;
}

.skeedee-dark .reservation-time .button-slot .ant-btn{
  background: #535a8c;
  border: 0;
  color: #fff;
}

.skeedee-dark form .ant-form textarea {
  border: 0;
  background: #e9e3ff;
}

.skeedee-dark .ant-tag {
  border: 0;
  margin-right: 0;
  background: #e9e3ff;
}

.skeedee-dark .ant-btn-dashed {
  background: transparent;
  color: #e9e3ff;
  border-color: #e9e3ff;
}

.skeedee-dark .ant-btn-dangerous {
  background: transparent;
  color: #f89697;
  border-color: #f89697;
}

.skeedee-dark form .ant-input,
.skeedee-dark .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.skeedee-dark .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  background-color: #e8e3ff;
}
:root {
  --base-radius: 4px;
  --main-wrapper-heigth: 0px;
  --widget-purple: #7333fb;
  --widget-orange: #ff8f63;
  --widget-yellow: #ffca39;
  --widget-green: #42bb56;
  --widget-main: #fff;
  --widget-sidebar: #edf2fb;
}

#root {
  height: 100%;
}

.avatar-uploader {
  width: auto;
  margin: 0 auto;
  display: block;
}

.ant-table-small {
  max-height: calc(0px);
  max-height: calc(var(--main-wrapper-heigth));
  overflow: hidden;
}

.ant-table-body {
  max-height: calc(0px - 120px) !important;
  max-height: calc(var(--main-wrapper-heigth) - 120px) !important;
}

.ant-drawer-body {
  padding: 0;
}

.reservation-drawer > .ant-drawer-content {
  background: #001529;
}

.reservation-drawer {
  background: #ffffff;
}

.ant-picker-now-btn {
  display: none;
}

@media only screen and (max-width: 800px) {
  .ant-layout-content {
    padding: 24px 0 !important;
  }
  .phone-button-margin {
    margin: 0 24px;
    width: 100%;
  }
  .phone-button-margin-full {
    margin-left: 24px;
    width: calc(100% - 48px) !important;
  }
  .ant-tabs-nav-wrap {
    margin: 0 24px;
  }
  .mobile-left-margin {
    margin-left: 24px;
  }
  .mobile-left-padding {
    padding-left: 24px;
  }
  .ant-picker-now-btn {
    display: none;
  }
}

.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-disabled.ant-select-multiple .ant-select-selector,
.ant-picker.ant-picker-disabled,
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.65);
  background: rgba(245, 245, 245, 0.33);
}

.site-page-header {
  border: 1px solid rgba(0, 0, 0, 0.22);
}

.no-arrows-input > .ant-input-number-handler-wrap {
  display: none;
}

.main-drawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-body {
  background: #001529;
}

.spin-ant-d {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}

.adding-margin {
  margin-top: 20px;
}

.spin-container {
  text-align: center;
  width: 100%;
}



.flex { display: flex; }
.flex-column { flex-direction: column; }
.flex-grow-1 { flex-grow: 1; }
.flex-shrink-0 { flex-shrink: 0; }
.flex-wrap { flex-wrap: wrap; }

.align-items-center { align-items: center; }
.justify-content-center { justify-content: center; }
.justify-content-right { justify-content: right; }

.w-25 { width: 25%; }
.w-full { width: 100%; }

.break-work { word-break: break-word; }
.text-wrap { white-space: normal; }
.text-12 { font-size: 12px; }
.text-14 { font-size: 14px; }
.text-16 { font-size: 16px; }
.text-bold { font-weight: bold; }
.text-center { text-align: center; }

.mb-1 { margin-bottom: 4px; }
.mb-2 { margin-bottom: 8px; }
.mb-3 { margin-bottom: 16px; }
.mb-4 { margin-bottom: 24px; }
.ml-1 { margin-left: 4px; }
.ml-2 { margin-left: 8px; }
.ml-3 { margin-left: 16px; }
.ml-4 { margin-left: 24px; }
.mr-1 { margin-right: 4px; }
.mr-2 { margin-right: 8px; }
.mr-3 { margin-right: 16px; }
.mr-4 { margin-right: 24px; }
.mt-1 { margin-top: 4px; }
.mt-2 { margin-top: 8px; }
.mt-3 { margin-top: 16px; }
.mt-4 { margin-top: 24px; }
.m--1 { margin: -4px; }

.p-1 { padding: 4px; }



:root {
  --color-green: rgb(124, 179, 5);
  --color-red: rgb(212, 56, 13);
}

.color-gray { color: #999; }
.color-green { color: rgb(124, 179, 5); color: var(--color-green); }
.color-red { color: rgb(212, 56, 13); color: var(--color-red); }


.skeedee-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

.skeedee-list .list-item {
  background: #fff;
  font-size: 11px;
  padding: 15px 16px;
}

/* .skeedee-dark .skeedee-list .list-item {
  background: #585a70;
} */

.skeedee-list .list-item:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.skeedee-list .list-item:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.skeedee-list .list-item:not(:last-child) {
  box-shadow: 0px 1px 0px 0px #0f0aa733;
  margin-bottom: 1px;
}

/* .skeedee-dark .skeedee-list .list-item:not(:last-child) {
  box-shadow: 0px 1px 0px 0px #0f0aa733;
} */

.skeedee-list .list-item:active,
.skeedee-list .list-item:hover {
  background: #8b9ef933;
  box-shadow: 0px 0px 10px 0px #d2daff4d inset;
}

/* .skeedee-dark .skeedee-list .list-item:active,
.skeedee-dark .skeedee-list .list-item:hover {
  background: #484a5c;
  box-shadow: none;
} */



.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content.ant-picker-calendar-date-content {
  height: 0;
}



/* .skeedee-dark .ant-carousel {
  color: #fff;
} */

.ant-carousel .slick-slide.slick-slide {
  height: 1px;
}

.ant-carousel .slick-slide.slick-slide.slick-active,
.ant-carousel .slick-slide.slick-slide.slick-current {
  height: auto;
}

.ant-carousel .slick-dots {
  display: none !important;
}



/* .skeedee-dark .ant-form-item-label > label {
  color: #fff;
}

.skeedee-dark .ant-input,
.skeedee-dark .ant-input-number,
.skeedee-dark .ant-input-affix-wrapper {
  background: #585a70;
  border-color: #0f0aa733;
  color: #fff;
}

.skeedee-dark .ant-input-number {
  background: #484a5c;
}

.skeedee-dark .ant-input:focus,
.skeedee-dark .ant-input-focused,
.skeedee-dark .ant-input:hover,
.skeedee-dark .ant-input-number:focus,
.skeedee-dark .ant-input-number-focused,
.skeedee-dark .ant-input-number:hover,
.skeedee-dark .ant-input-affix-wrapper:focus,
.skeedee-dark .ant-input-affix-wrapper-focused,
.skeedee-dark .ant-input-affix-wrapper:hover {
  background: #484a5c;
  border-color: #efc905;
}

.skeedee-dark .ant-input-affix-wrapper .ant-input,
.skeedee-dark .ant-input-affix-wrapper .ant-input:focus,
.skeedee-dark .ant-input-affix-wrapper .ant-input:hover {
  background: transparent;
}

.skeedee-dark .ant-input-suffix svg path {
  fill: rgba(255, 255, 255, .65);
}

.skeedee-dark .ant-input-suffix svg:hover path {
  fill: #fff;
}

.skeedee-dark .ant-checkbox-wrapper {
  color: #fff;
}

.skeedee-dark .ant-input-group-addon {
  border: none;
}

.skeedee-dark .ant-select,
.skeedee-dark .ant-input-group-addon .ant-select-open .ant-select-selector,
.skeedee-dark .ant-input-group-addon .ant-select-focused .ant-select-selector {
  color: #fff;
}

.skeedee-dark .ant-select-arrow .anticon > svg path {
  fill: rgba(255, 255, 255, .65);
}

.skeedee-dark .ant-select:hover .ant-select-arrow .anticon > svg path,
.skeedee-dark .ant-select-open .ant-select-arrow .anticon > svg path {
  fill: #fff;
}

.skeedee-dark .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.skeedee-dark .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background: #585a70;
  border-color: #0f0aa733;
}

.skeedee-dark .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.skeedee-dark .ant-input-group-addon .ant-select-focused.ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background: #484a5c;
  border-color: #efc905;
  box-shadow: 0 0 0 2px rgba(239, 201, 5, .2);
}

.skeedee-dark .ant-input-group-addon {
  background: #484a5c;
}

.skeedee-dark .ant-select-dropdown {
  background: #585a70;
}

.skeedee-dark .ant-select-item-option {
  color: #fff;
}

.skeedee-dark .ant-select-item-option:hover,
.skeedee-dark .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: #484a5c;
}

.skeedee-dark .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: rgba(239, 201, 5, .25);
}

.skeedee-dark .ant-divider {
  border-color: rgba(47, 48, 61, .25);
} */



.phone-input-group.ant-input-group.ant-input-group-compact {
  display: flex;
}

.phone-input-group.ant-input-group.ant-input-group-compact .ant-select {
  flex-shrink: 0;
  width: 120px;
}

.phone-input-group.ant-input-group.ant-input-group-compact > .ant-input {
  flex-grow: 1;
}



/* .skeedee-dark .ant-tabs {
  color: #fff;
}

.skeedee-dark .ant-tabs-top > .ant-tabs-nav::before {
  border-color: rgba(47, 48, 61, .65);
}

.skeedee-dark .ant-tag {
  background: #585a70;
  border-color: #484a5c;
  color: #fff;
}

.skeedee-dark .ant-tag:hover {
  background: #484a5c;
}

.skeedee-dark .ant-tabs-dropdown-menu {
  background: #585a70;
}

.skeedee-dark .ant-tabs-dropdown-menu-item {
  color: #fff;
}

.skeedee-dark .ant-tabs-dropdown-menu-item:hover {
  background: #484a5c;
}



.skeedee-dark .ant-btn-dashed {
  background: #585a70;
  border-color: #484a5c;
  color: #fff;
}

.skeedee-dark .ant-btn-dashed:hover {
  background: #484a5c;
  color: #40a9ff;
}

.skeedee-dark .ant-btn-dashed.ant-btn-dangerous {
  border-color: #ff7875;
}

.skeedee-dark .ant-btn-dashed.ant-btn-dangerous:hover {
  color: #ff7875;
}



.skeedee-dark .ant-drawer-header {
  background: #3f404a;
  border-color: #484a5c;
}

.skeedee-dark .ant-drawer-title {
  color: #fff;
}

.skeedee-dark .ant-drawer-header button {
  color: rgba(255, 255, 255, .65);
}

.skeedee-dark .ant-drawer-header button:hover {
  color: #fff;
}

.skeedee-dark .ant-drawer-body {
  background: #3f404a;
} */

