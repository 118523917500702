.reservation-info,
.reservation-info ul {
  list-style: none;
  padding: 0;
}

.location-info {
  display: flex;
}

.location-info img {
  flex-shrink: 0;
  margin-right: 4px;
}
