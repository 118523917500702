.reservation-calendar--months .month-item {
  justify-content: center;
  width: 33%;
}

.reservation-calendar--months .month-item:first-child,
.reservation-calendar--months .month-item:last-child {
  color: #999;
}

.reservation-calendar--months .month-item:first-child {
  justify-content: left;
}

.reservation-calendar--months .month-item:last-child {
  justify-content: right;
}

.reservation-calendar--months .month-item span {
  cursor: pointer;
  text-transform: uppercase;
}

.reservation-calendar--days {
  overflow-x: scroll;
}

.reservation-calendar--days .day-item {
  float: left;
}

.reservation-calendar--days .day-item:last-child {
  margin-right: 0;
}

.reservation-calendar--days .day-item .day-name {
  color: #777;
  text-transform: uppercase;
  width: 32px;
}

.reservation-calendar--days .day-item--disabled .day-name {
  color: #bbb;
}

.reservation-calendar--days .day-item .day-button {
  border-radius: 100%;
  cursor: pointer;
  height: 32px;
  width: 32px;
}

.reservation-calendar--days .day-item--disabled .day-button {
  color: #999;
  cursor: default;
}

.reservation-calendar--days .day-item--current .day-button {
  background: #0f0aa733;
  cursor: default;
}
