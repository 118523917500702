.widget-viewport {
  background: #f6f7ff;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', 'Quicksand', Helvetica, sans-serif;
  font-size: 14px;
  margin: 0;
  min-height: 100%;
  padding: 70px 24px 81px;
  transition-duration: 0.1s;
}

/* .skeedee-dark .widget-viewport {
  background: #3f404a;
  color: #fff;
} */
