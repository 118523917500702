.about-us {
  font-size: 14px;
  word-break: break-all;
}

/* .skeedee-dark .about-us .ant-typography {
  color: #fff;
} */

.about-us .avatar img {
  border-radius: 100%;
  height: 64px;
  width: 64px;
}
