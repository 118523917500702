.reservation-locations .list-item {
  cursor: pointer;
  display: flex;
}

.reservation-locations .avatar-slot {
  background: #c4c4c4;
  border-radius: 10px;
  flex-shrink: 0;
  height: 60px;
  margin-right: 16px;
  overflow: hidden;
  position: relative;
  width: 60px;
}

.reservation-locations .title {
  font-size: 16px;
}
