.provider-link {
  font-size: 11px;
  margin-top: 22px;
  padding: 0 36px;
  text-align: center;
}

.provider-link a {
  color: #0f0aa7;
  font-weight: bold;
  text-decoration: underline;
  text-transform: uppercase;
}

/* .skeedee-dark .provider-link a {
  color: #efc905;
} */
