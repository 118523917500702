.reservation-services .list-item {
  cursor: pointer;
}

.reservation-services.skeedee-list .list-item--disabled {
  cursor: default;
  opacity: .5;
}

.reservation-services.skeedee-list .list-item--selected {
  border-right: 5px solid var(--color-green);
  padding-right: 11px;
}

.reservation-services .title {
  font-size: 16px;
}

.reservation-services .price {
  font-size: 22px;
}

.reservation-services .selectivity-icon {
  font-size: 20px;
}

.reservation-services .service-duration {
  color: #e5165c;
  font-size: 14px;
}

.reservation-services .description {
  color: #777;
  font-size: 13px;
  line-height: 18px;
}

.reservation-services .more-button {
  font-size: 12px;
  margin-top: -8px;
}
