.skeedee-loader .anticon-loading {
  font-size: 64px;
  margin: 10% auto 50px auto;
  display: block;
}

.skeedee-loader h2.ant-typography {
  text-align: center;
  font-weight: 200 !important;
}

/* .skeedee-dark .skeedee-loader h2.ant-typography {
  color: #fff;
} */
