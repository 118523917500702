.reservation-cart.skeedee-list .list-item {
  font-size: 16px;
}

.reservation-cart.skeedee-list .list-item--clickable {
  cursor: pointer;
}

.reservation-cart.skeedee-list .list-item--disabled {
  color: #999;
  cursor: default;
}

.reservation-cart .list-item .icon-slot {
  background: #f6f7ff;
  border-radius: 100%;
  height: 48px;
  margin-right: 12px;
  width: 48px;
}

.reservation-cart .list-item .icon-button {
  border-radius: 100%;
  height: 32px;
  width: 32px;
}

.reservation-cart .list-item .icon-button svg {
  color: #999;
}

.reservation-cart .list-item .services-list {
  border-top: 1px solid #f6f7ff;
  list-style: none;
  margin: 15px 0 0;
  padding: 15px 0 0;
}

.reservation-cart .list-item .services-list li:not(:first-child) {
  margin-top: 8px;
}

.reservation-cart--continue-button.ant-btn {
  bottom: 81px;
  left: 24px;
  position: fixed;
  right: 24px;
}
