.skeedee-dark .widget-viewport{
  background: #262a48;
}

.skeedee-dark .reservation-header > label-slot,
.skeedee-dark .reservation-services .title,
.skeedee-dark .reservation-services .price,
.skeedee-dark .provider-link,
.skeedee-dark .skeedee-list .list-item,
.skeedee-dark .reservation-masters b.title,
.skeedee-dark .reservation-slide,
.skeedee-dark form .ant-form label,
.skeedee-dark .ant-tabs-tab .ant-tabs-tab-btn,
.skeedee-dark .ant-form-item-label>label,
.skeedee-dark .ant-checkbox-wrapper,
.skeedee-dark .about-us > article,
.skeedee-dark .reservation-header .label-slot {
  color: #fff;
}

.skeedee-dark ul.skeedee-list > li.list-item {
  background: #535a8c;
}

.skeedee-dark .reservation-header .label-slot b,
.skeedee-dark .provider-link a,
.skeedee-dark .ant-btn-link.reviews-button {
  color: #aca2dc;
}

.skeedee-dark .widget-toolbar > ul {
  background: #e9e3ff;
}

.skeedee-dark .skeedee-list .list-item:active,
.skeedee-dark .skeedee-list .list-item:hover {
  box-shadow: inset 0 0 10px 0 rgb(23 23 24 / 30%);
}

.skeedee-dark .reservation-masters li.list-item .ant-col > div {
  color: #d6d0ea;
}

.skeedee-dark .reservation-header .icon-button {
  background: #e5dffb;
}

.skeedee-dark .reservation-slide .ant-picker-calendar {
  background: #e5dffb;
}

.skeedee-dark .ant-picker-calendar-full .ant-picker-panel {
  background: transparent;
}

.skeedee-dark .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 0;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  border-top: 2px solid #e5dffb;
}

.skeedee-dark .reservation-time .button-slot .ant-btn{
  background: #535a8c;
  border: 0;
  color: #fff;
}

.skeedee-dark form .ant-form textarea {
  border: 0;
  background: #e9e3ff;
}

.skeedee-dark .ant-tag {
  border: 0;
  margin-right: 0;
  background: #e9e3ff;
}

.skeedee-dark .ant-btn-dashed {
  background: transparent;
  color: #e9e3ff;
  border-color: #e9e3ff;
}

.skeedee-dark .ant-btn-dangerous {
  background: transparent;
  color: #f89697;
  border-color: #f89697;
}

.skeedee-dark form .ant-input,
.skeedee-dark .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.skeedee-dark .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  background-color: #e8e3ff;
}